import React, { useEffect, useState } from "react";
import Phaser from "phaser";
import GameComponent from "../components/GameComponent";
import AnimatedTiles from "phaser-animated-tiles/src/plugin/main";
import UIScene from "../components/UIScene";
import axios from "axios";
import Modal from "../components/Modal";

const Alchemist = () => {
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState([]);
  const [monsters, setMonsters] = useState([]);
  const token = sessionStorage.getItem("token");
  const props = JSON.parse(sessionStorage.getItem("props"));
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const authconfig = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };
  let potionCount = 0;
  if (props && props.PotionElixir) {
    potionCount = props.PotionElixir;
  }

  const closeModal = () => {
    setModalShow(false);
  };

  useEffect(() => {
    getEvolvables();
  }, []);

  const getEvolvables = async () => {
    const result = await axios.get(
      API_ENDPOINT + "getUserMonsters",
      authconfig
    ); //.then((result) => {
    const userMonsters = result?.data.data?.monsters;
    if (userMonsters) {
      let monsters = [];
      for (let index = 0; index < userMonsters.length; index++) {
        if (userMonsters[index].can_evolve) {
          //this.evolvables.push(userMonsters[index]);
          monsters.push(userMonsters[index]);
        }
      }
      setMonsters(monsters);
      console.log(">>> monster info is retrieved.");
    } else {
      console.log(
        ">>> something went wrong with the monster fetching process."
      );
    }
    //});
  };

  class Example extends Phaser.Scene {
    constructor() {
      super({ key: "alchemist-scene", active: true });
      this.evolvables = monsters;
      this.uniqueItems = [];
    }

    genRanHex = (size) =>
      [...Array(size)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join("");

    preload() {
      // #region LOADING ASSETS
      this.load.image("Bench-3", "assets/alchemist/sprites/Props/Bench-3.png");
      this.load.image("Book-8", "assets/alchemist/sprites/Props/Book-8.png");
      this.load.image(
        "BookShelf-7",
        "assets/alchemist/sprites/Props/BookShelf-7.png"
      );
      this.load.image(
        "BookShelf-9",
        "assets/alchemist/sprites/Props/BookShelf-9.png"
      );
      this.load.image(
        "BookStand-2",
        "assets/alchemist/sprites/Props/BookStand-2.png"
      );
      this.load.image(
        "Brazier-Golden",
        "assets/alchemist/sprites/AnimatedProps/Brazier-Golden.png"
      );
      this.load.image(
        "Cabinet-5-Animated",
        "assets/alchemist/sprites/AnimatedProps/Cabinet-5-Animated.png"
      );
      this.load.image(
        "Candelabra-3",
        "assets/alchemist/sprites/Props/Candelabra-3.png"
      );
      this.load.image(
        "Candle-Iron-wLight",
        "assets/alchemist/sprites/Props/Candle-Iron-wLight.png"
      );
      this.load.image(
        "RuneStone-3",
        "assets/alchemist/sprites/AnimatedProps/RuneStone-3.png"
      );
      this.load.image(
        "Carpet-Tiles",
        "assets/alchemist/sprites/Tiles/Carpet-Tiles.png"
      );
      this.load.image("Coins-1", "assets/alchemist/sprites/Props/Coins-1.png");
      this.load.image("Coins-6", "assets/alchemist/sprites/Props/Coins-6.png");
      this.load.image(
        "Door-Close-Left-Vertical",
        "assets/alchemist/sprites/AnimatedTiles/Door-Close-Left-Vertical.png"
      );
      this.load.image("Flag-3", "assets/alchemist/sprites/Props/Flag-3.png");
      this.load.image(
        "Fountain-2",
        "assets/alchemist/sprites/Props/Fountain-2.png"
      );
      this.load.image(
        "GoldChest-Open-wGold",
        "assets/alchemist/sprites/AnimatedProps/GoldChest-Open-wGold.png"
      );
      this.load.image(
        "Pool-Tiles",
        "assets/alchemist/sprites/Tiles/Pool-Tiles.png"
      );
      this.load.image(
        "Potion-4",
        "assets/alchemist/sprites/Props/Potion-4.png"
      );
      this.load.image(
        "Potion-6",
        "assets/alchemist/sprites/Props/Potion-6.png"
      );
      this.load.image(
        "Potion-11",
        "assets/alchemist/sprites/Props/Potion-11.png"
      );
      this.load.image("Props", "assets/alchemist/sprites/Props.png");
      this.load.image(
        "Sarcophagus-Animated-1",
        "assets/alchemist/sprites/AnimatedProps/Sarcophagus-Animated-1.png"
      );
      this.load.image(
        "Stairs-Tiles",
        "assets/alchemist/sprites/Tiles/Stairs-Tiles.png"
      );
      this.load.image("Table-3", "assets/alchemist/sprites/Props/Table-3.png");
      this.load.image("Tiles", "assets/alchemist/sprites/Tiles.png");
      this.load.image(
        "Torch-Animated",
        "assets/alchemist/sprites/AnimatedProps/Torch-Animated.png"
      );
      this.load.image(
        "Trap-Spikes-Floor",
        "assets/alchemist/sprites/AnimatedProps/Trap-Spikes-Floor.png"
      );
      this.load.image(
        "WallTiles",
        "assets/alchemist/sprites/Tiles/WallTiles.png"
      );
      this.load.image(
        "WallFountain",
        "assets/alchemist/sprites/WallFountain.png"
      );
      this.load.image("eggs", "assets/alchemist/sprites/eggs.png");
      this.load.audio("Evolve", "assets/alchemist/evolve.wav");
      this.load.audio("PickPotion", "assets/alchemist/potionpick.wav");

      // teleport animated 3 to be done
      this.load.spritesheet(
        "Teleport-Animated-1",
        "assets/alchemist/sprites/AnimatedProps/Teleport-Animated-1.png",
        { frameWidth: 72, frameHeight: 72 }
      );
      this.load.spritesheet("Portal", "assets/alchemist/vfx.png", {
        frameWidth: 64,
        frameHeight: 64,
      });
      this.load.spritesheet("Smoke", "assets/alchemist/smoke.png", {
        frameWidth: 32,
        frameHeight: 32,
      });
      console.log(this.uniqueItems);
      const monsterNames = [
        "AntleredRascal",
        "ArmoredGoliath",
        "BeastlyImpalerIdleSide",
        "BladeHellion",
        "BloodDrinker",
        "BulgingIncubus",
        "ClawedAbomination",
        "CrimsonImp",
        "DastardlyCrusher",
        "DeadlyCambion",
        "DemonicArachnid",
        "DepravedBlackguard",
        "EbonAstaroth",
        "FledglingDemon",
        "FloatingEye",
        "FoulGouger",
        "GrinningGremlin",
        "HornedBrute",
        "NefariousScamp",
        "PitBalor",
        "PointedDemonspawn",
        "ProngedFury",
        "RascallyDemonling",
        "RedDevil",
        "SkeweringStalker",
        "SpikedRavager",
        "TaintedScoundrel",
        "ViciousMiscreant",
        "WarpSkull",
        "WickedWretch",
      ];

      monsterNames.forEach((monster) => {
        this.load.spritesheet(monster, `assets/monsters/${monster}.png`, {
          frameWidth: 16,
          frameHeight: 16,
        });
      });

      this.load.image("evolved", "assets/monsters/evolved.png");

      this.load.tilemapTiledJSON("map", "assets/alchemist/pupu-alchemist.json");
      this.load.plugin(
        "rexoutlinepipelineplugin",
        "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexoutlinepipelineplugin.min.js",
        true
      );
      // #endregion
    }

    create() {
      try {
        const map = this.make.tilemap({
          key: "map",
          tileWidth: 24,
          tileHeight: 24,
        });

        // Apply the smaller zoom factor to fit the world within the canvas
        this.cameras.main.setZoom(2);
        this.cameras.main.centerOn(200, 200);

        const worldWidth = 720;
        const worldHeight = 480;
        this.cameras.main.setBounds(0, 0, worldWidth, worldHeight);

        //#region TILESETS and TILELAYERS

        const bench3 = map.addTilesetImage("Bench-3", "Bench-3");
        const book8 = map.addTilesetImage("Book-8", "Book-8");
        const bookshelf7 = map.addTilesetImage("BookShelf-7", "BookShelf-7");
        const bookshelf9 = map.addTilesetImage("BookShelf-9", "BookShelf-9");
        const bookstand2 = map.addTilesetImage("BookStand-2", "BookStand-2");
        const braziergolden = map.addTilesetImage(
          "Brazier-Golden",
          "Brazier-Golden"
        );
        const cabinet5 = map.addTilesetImage(
          "Cabinet-5-Animated",
          "Cabinet-5-Animated"
        );
        const runestone3 = map.addTilesetImage("RuneStone-3", "RuneStone-3");
        const carpet = map.addTilesetImage("Carpet-Tiles", "Carpet-Tiles");
        const candelabra3 = map.addTilesetImage("Candelabra-3", "Candelabra-3");
        const candleironwlight = map.addTilesetImage(
          "Candle-Iron-wLight",
          "Candle-Iron-wLight"
        );
        const coins1 = map.addTilesetImage("Coins-1", "Coins-1");
        const coins6 = map.addTilesetImage("Coins-6", "Coins-6");
        const doorvertical = map.addTilesetImage(
          "Door-Close-Left-Vertical",
          "Door-Close-Left-Vertical"
        );
        const flag3 = map.addTilesetImage("Flag-3", "Flag-3");
        const fountain2 = map.addTilesetImage("Fountain-2", "Fountain-2");
        const chestwithgold = map.addTilesetImage(
          "GoldChest-Open-wGold",
          "GoldChest-Open-wGold"
        );
        const pooltiles = map.addTilesetImage("Pool-Tiles", "Pool-Tiles");
        const potion4 = map.addTilesetImage("Potion-4", "Potion-4");
        const potion6 = map.addTilesetImage("Potion-6", "Potion-6");
        const potion11 = map.addTilesetImage("Potion-11", "Potion-11");
        const props = map.addTilesetImage("Props", "Props");
        const sarcophagus1 = map.addTilesetImage(
          "Sarcophagus-Animated-1",
          "Sarcophagus-Animated-1"
        );
        const stairs = map.addTilesetImage("Stairs-Tiles", "Stairs-Tiles");
        const table3 = map.addTilesetImage("Table-3", "Table-3");
        const tiles = map.addTilesetImage("Tiles", "Tiles");
        const torch = map.addTilesetImage("Torch-Animated", "Torch-Animated");
        const trapspikes = map.addTilesetImage(
          "Trap-Spikes-Floor",
          "Trap-Spikes-Floor"
        );
        const wallfountain = map.addTilesetImage(
          "WallFountain",
          "WallFountain"
        );
        const walltiles = map.addTilesetImage("WallTiles", "WallTiles");
        const eggs = map.addTilesetImage("eggs", "eggs");
        const teleportanimated = map.addTilesetImage(
          "Teleport-Animated-1",
          "Teleport-Animated-1"
        );
        const smokeanim = map.addTilesetImage("Smoke", "Smoke");

        const floor = map.createLayer(
          "Floor",
          [
            bench3,
            book8,
            bookshelf7,
            bookshelf9,
            bookstand2,
            braziergolden,
            cabinet5,
            runestone3,
            carpet,
            candleironwlight,
            candelabra3,
            coins1,
            coins6,
            doorvertical,
            flag3,
            fountain2,
            chestwithgold,
            pooltiles,
            potion4,
            potion6,
            potion11,
            props,
            sarcophagus1,
            stairs,
            table3,
            tiles,
            torch,
            trapspikes,
            wallfountain,
            walltiles,
            teleportanimated,
            smokeanim,
            eggs,
          ],
          0,
          0
        );
        const doors1 = map.createLayer(
          "Doors1",
          [
            bench3,
            book8,
            bookshelf7,
            bookshelf9,
            bookstand2,
            braziergolden,
            cabinet5,
            runestone3,
            carpet,
            candleironwlight,
            candelabra3,
            coins1,
            coins6,
            doorvertical,
            flag3,
            fountain2,
            chestwithgold,
            pooltiles,
            potion4,
            potion6,
            potion11,
            props,
            sarcophagus1,
            stairs,
            table3,
            tiles,
            torch,
            trapspikes,
            wallfountain,
            walltiles,
            teleportanimated,
            smokeanim,
            eggs,
          ],
          0,
          0
        );
        const labyrinth = map.createLayer(
          "Labyrinth",
          [
            bench3,
            book8,
            bookshelf7,
            bookshelf9,
            bookstand2,
            braziergolden,
            cabinet5,
            runestone3,
            candleironwlight,
            candelabra3,
            carpet,
            coins1,
            coins6,
            doorvertical,
            flag3,
            fountain2,
            chestwithgold,
            pooltiles,
            potion4,
            potion6,
            potion11,
            props,
            sarcophagus1,
            stairs,
            table3,
            tiles,
            torch,
            trapspikes,
            wallfountain,
            walltiles,
            teleportanimated,
            smokeanim,
            eggs,
          ],
          0,
          0
        );
        const props1 = map.createLayer(
          "Props1",
          [
            bench3,
            book8,
            bookshelf7,
            bookshelf9,
            bookstand2,
            braziergolden,
            cabinet5,
            runestone3,
            carpet,
            candleironwlight,
            candelabra3,
            coins1,
            coins6,
            doorvertical,
            flag3,
            fountain2,
            chestwithgold,
            pooltiles,
            potion4,
            potion6,
            potion11,
            props,
            sarcophagus1,
            stairs,
            table3,
            tiles,
            torch,
            trapspikes,
            wallfountain,
            walltiles,
            teleportanimated,
            smokeanim,
            eggs,
          ],
          0,
          0
        );
        const doors2 = map.createLayer(
          "Doors2",
          [
            bench3,
            book8,
            bookshelf7,
            bookshelf9,
            bookstand2,
            braziergolden,
            cabinet5,
            runestone3,
            carpet,
            candleironwlight,
            candelabra3,
            coins1,
            coins6,
            doorvertical,
            flag3,
            fountain2,
            chestwithgold,
            pooltiles,
            potion4,
            potion6,
            potion11,
            props,
            sarcophagus1,
            stairs,
            table3,
            tiles,
            torch,
            trapspikes,
            wallfountain,
            walltiles,
            teleportanimated,
            smokeanim,
            eggs,
          ],
          0,
          0
        );
        const props2 = map.createLayer(
          "Props2",
          [
            bench3,
            book8,
            bookshelf7,
            bookshelf9,
            bookstand2,
            braziergolden,
            cabinet5,
            runestone3,
            carpet,
            candleironwlight,
            candelabra3,
            coins1,
            coins6,
            doorvertical,
            flag3,
            fountain2,
            chestwithgold,
            pooltiles,
            potion4,
            potion6,
            potion11,
            props,
            sarcophagus1,
            stairs,
            table3,
            tiles,
            torch,
            trapspikes,
            wallfountain,
            walltiles,
            teleportanimated,
            smokeanim,
            eggs,
          ],
          0,
          0
        );
        const transformation = map.createLayer(
          "Transformation",
          [
            bench3,
            book8,
            bookshelf7,
            bookshelf9,
            bookstand2,
            braziergolden,
            cabinet5,
            runestone3,
            carpet,
            candleironwlight,
            candelabra3,
            coins1,
            coins6,
            doorvertical,
            flag3,
            fountain2,
            chestwithgold,
            pooltiles,
            potion4,
            potion6,
            potion11,
            props,
            sarcophagus1,
            stairs,
            table3,
            tiles,
            torch,
            trapspikes,
            wallfountain,
            walltiles,
            teleportanimated,
            smokeanim,
            eggs,
          ],
          0,
          0
        );

        //#endregion

        const evolveConfig1 = {
          key: "evolve1",
          frames: this.anims.generateFrameNumbers("Teleport-Animated-1", {
            start: 0,
            end: 16,
          }),
          frameRate: 5,
          repeat: -1,
        };
        const evolveConfig2 = {
          key: "evolve2",
          frames: this.anims.generateFrameNumbers("Teleport-Animated-1", {
            start: 0,
            end: 1,
          }),
          frameRate: 5,
          repeat: -1,
        };
        const vfxConfig1 = {
          key: "playVFX",
          frames: this.anims.generateFrameNumbers("Portal", {
            start: 0,
            end: 16,
          }),
          frameRate: 5,
          repeat: -1,
        };
        const smokeConfig1 = {
          key: "playSmoke",
          frames: this.anims.generateFrameNumbers("Smoke", {
            start: 0,
            end: 4,
          }),
          frameRate: 5,
          repeat: -1,
        };

        const evolveMonster = this.sound.add("Evolve");
        const pickedPotion = this.sound.add("PickPotion");

        this.anims.create(evolveConfig1);
        this.anims.create(smokeConfig1);

        //#region MONSTERS
        let originX = 400;
        let originY = 280;
        if (this.evolvables.length > 0) {
          this.anims.create(vfxConfig1);
          const clickableEvolve = this.add.sprite(
            324,
            348,
            "Teleport-Animated-1"
          );
          clickableEvolve.setInteractive({ useHandCursor: true });
          clickableEvolve.play("evolve1");

          let evolveActive = false;
          let currentMonster = null;
          setTimeout(1500);
          this.evolvables.map((monster, index) => {
            const key = this.genRanHex(5);
            const name = monster.monster_name.replace(" ", "");
            const evolveName = monster.next_evolve_name.replace(" ", "");
            if (!this.anims.exists(key)) {
              this.anims.create({
                key: key,
                frames: this.anims.generateFrameNumbers(name, {
                  start: 0,
                  end: 3,
                }),
                frameRate: 3,
                repeat: -1,
              });
            }
            const monst = this.add
              .sprite(originX, originY, name)
              .setInteractive({ useHandCursor: true });
            const monstText = this.add.text(
              originX + 10,
              originY,
              `${monster.monster_name} #(${monster.id})`,
              {
                font: "8px Chicago_12", // Increase font size to counteract the zoom effect
                fill: "#ffee00",
                backgroundColor: "#000000",
                resolution: 4,
              }
            );
            monst.monsterId = monster.id;
            monst.nextEvolveName = evolveName;

            monst.on("pointerdown", () => {
              if (!evolveActive) {
                evolveActive = true;
                monst.setVisible(false);
                monstText.setVisible(false);
                currentMonster = monst;
                this.time.delayedCall(300, () => {
                  // Reposition the sprite to the new location
                  currentMonster.setPosition(324, 338);
                  // Make the sprite visible again
                  currentMonster.setVisible(true);
                });
              }
            });
            monst.play(key);
            originY += 20;
          });
          clickableEvolve.on("pointerdown", async () => {
            if (evolveActive && currentMonster) {
              evolveMonster.play();
              const vfx = this.add.sprite(324, 348, "Portal");
              vfx.play("playVFX");

              const monsterId = currentMonster.monsterId;
              const monsterEvolveName = currentMonster.nextEvolveName;
              const evolvebody = {
                user_monster_id: monsterId,
              };
              try {
                const result = await axios.post(
                  API_ENDPOINT + "evolveMonster",
                  evolvebody,
                  authconfig
                );
                //.then((result) => {
                const returnResult = result.data?.status;
                if (returnResult === "success") {
                  this.time.delayedCall(1500, () => {
                    currentMonster.destroy();
                    currentMonster = null;
                    evolveActive = false;
                    vfx.destroy();

                    // if successful...
                    const newAnimKey = this.genRanHex(5);
                    this.anims.create({
                      key: newAnimKey,
                      frames: this.anims.generateFrameNumbers(
                        monsterEvolveName,
                        {
                          start: 0,
                          end: 3,
                        }
                      ),
                      frameRate: 3,
                      repeat: -1,
                    });
                    const newMonster = this.add.sprite(
                      324,
                      348,
                      monsterEvolveName
                    );
                    newMonster.play(newAnimKey);
                    const message = this.add.image(340, 332, "evolved");

                    setTimeout(() => {
                      newMonster.destroy();
                      message.destroy();
                    }, 3200);
                  });
                } else {
                  setModalShow(true);
                  setMessage("Oh no!", result.data?.message);
                }
                if (returnResult === "error") {
                  console.log(">>> " + result.data?.data?.message);
                }
                //});
                evolveActive = false;
              } catch (error) {
                console.warn(error);
              }
            }
          });
        } else {
          const justEvolve = this.add.sprite(324, 348, "Teleport-Animated-1");
          this.anims.create(evolveConfig2);
        }
        //#endregion
        for (let i = 0; i < potionCount; i++) {
          let x = Phaser.Math.Between(99, 600);
          let y = Phaser.Math.Between(200, 300);

          const potion = this.add
            .image(x, y, "Potion-4")
            .setInteractive({ useHandCursor: true });

          potion.on("pointerdown", () => {
            pickedPotion.play();
            const smokeanimation = this.add.sprite(potion.x, potion.y, "Smoke");
            smokeanimation.play("playSmoke");
            potion.destroy();
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const body = {
              itemcode: "PotionElixir",
            };
            const potionset = axios
              .post(API_ENDPOINT + "collectItem", body, config)
              .then(() => {
                setTimeout(() => {
                  smokeanimation.destroy();
                }, 1100);
              });
          });
        }

        this.sys.animatedTiles.init(map);
        //document.body.style.overflow = "hidden";
        //mouse drag map controls
        var cam = this.cameras.main;
        this.input.on("pointermove", function (p) {
          if (!p.isDown) return;
          cam.scrollX -= (p.x - p.prevPosition.x) / cam.zoom;
          cam.scrollY -= (p.y - p.prevPosition.y) / cam.zoom;
        });
      } catch (error) {
        console.log(error);
        setMessage("Oh no!", "Something went wrong!");
        //window.location.href='/';
      }
    }
  }

  const config = {
    type: Phaser.AUTO,
    pixelArt: true,
    backgroundColor: "rgb(173,160,111)",
    scene: [Example, UIScene],
    physics: {
      default: "arcade",
      arcade: {
        gravity: { y: 0 },
      },
    },
    scale: {
      mode: Phaser.Scale.RESIZE,
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    plugins: {
      scene: [
        {
          key: "AnimatedTiles",
          plugin: AnimatedTiles,
          mapping: "animatedTiles",
        },
      ],
    },
  };

  return (
    <>
      <GameComponent config={config} />
      {modalShow && (
        <Modal onClose={closeModal} props={[message[0], message[1]]} />
      )}
    </>
  );
};

export default Alchemist;
