import React, { useEffect } from "react";
import Phaser from "phaser";

const GameComponent = ({ config }) => {
  useEffect(() => {
    console.log(">>> Initializing Phaser Scene...");

    // Initialize the Phaser game with the provided configuration
    const game = new Phaser.Game(config);

    // Clean up the Phaser game instance on component unmount
    return () => {
      console.log(">>> Destroying Phaser Game...");
      game.destroy(true);
    };
  }, [config]); // Re-run effect if config changes

  return <div id="phaser-container"></div>;
};

export default GameComponent;
