import React, { useState, useEffect, useRef } from "react";
import Phaser from "phaser";
import GameComponent from "../components/GameComponent";
import AnimatedTiles from "phaser-animated-tiles/src/plugin/main";
import UIScene from "../components/UIScene";
import axios from "axios";
import Modal from "../components/Modal";

const Underworld = () => {
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState([]);
  const token = sessionStorage.getItem("token");
  const coinCount = parseInt(JSON.parse(sessionStorage.getItem("props")).Coin);
  const goldsackCount = parseInt(
    JSON.parse(sessionStorage.getItem("props")).GoldSack
  );
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const authconfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const phaserSceneRef = useRef(null);

  const closeModal = () => {
    setModalShow(false);
  };

  class Example extends Phaser.Scene {
    constructor() {
      super({ key: "underworld-scene", active: true });
      this.state = {
        isCollectedCoins: null,
        collectedCoins: 0,
        isGoldSackAvailable: false,
      };
      this.currentPopUp = null;
      this.monsterList = [];
    }

    async fetchMonsters() {
      this.monsterList = [];
      const result = await axios.get(
        API_ENDPOINT + "getUserMonsters",
        authconfig
      );
      try {
        if (result.data?.status === "success") {
          if (result.data?.data?.monsters) {
            const userMonsters = result.data.data.monsters;
            this.monsterList = [];
            this.monsterList = userMonsters;
          } else {
            console.log(">>> no monsters yet.");
          }
        } else {
          console.warn(result.data?.message);
        }
      } catch (error) {
        console.warn("Error fetching monsters:", error);
      }
    }

    genRanHex = (size) =>
      [...Array(size)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join("");

    createMonsters(monsters) {
      try {
        this.monsterGroup = this.physics.add.group();
        monsters.forEach((monster, index) => {
          const key = this.genRanHex(5);
          // Create an animation for the monster if it hasn't been created already
          if (!this.anims.exists(key)) {
            this.anims.create({
              key: key,
              frames: this.anims.generateFrameNumbers(
                monster.monster_name.replace(" ", ""),
                { frames: [0, 1, 2, 3] }
              ),
              frameRate: 3,
              repeat: -1,
            });
          }
          // Set custom bounds for the monster's movement
          const customBounds = {
            minY: 210,
            maxY: 280,
            minX: 110,
            maxX: 390,
          };
          // Create the monster sprite and enable physics
          const monsterSprite = this.monsterGroup
            .create(
              Phaser.Math.Between(customBounds.minX, customBounds.maxX),
              Phaser.Math.Between(customBounds.minY, customBounds.maxY),
              monster.monster_name.replace(" ", "")
            )
            .setInteractive({ useHandCursor: true })
            .play(key);

          // Disable gravity for this specific monster
          monsterSprite.body.setAllowGravity(false);

          // Set initial velocity to start movement in both directions
          monsterSprite.setVelocityY(10); // Move downwards initially
          monsterSprite.setVelocityX(10); // Move right initially
          monsterSprite.setBounce(1); // Set full bounce so they retain their speed

          // Store all monsters in an array so they can be updated in the `update` method
          this.monsters = this.monsters || [];
          this.monsters.push(monsterSprite);
          // Store custom bounds data in the monster's sprite
          monsterSprite.customBounds = customBounds;
          monsterSprite.setDepth(2);
          // Check some condition and apply the highlight if true
          if (monster.can_evolve) {
            const outlinePipeline = this.plugins.get(
              "rexoutlinepipelineplugin"
            );
            monsterSprite.setPipeline(outlinePipeline);
            outlinePipeline.add(monsterSprite, {
              thickness: 1,
              outlineColor: 0xffd700,
            });
            //monsterSprite.pipeline.setOutlineColor(0xffd700); // Set the highlight color, e.g., gold
          }
          if (monster.can_collect) {
            const outlinePipeline = this.plugins.get(
              "rexoutlinepipelineplugin"
            );
            monsterSprite.setPipeline(outlinePipeline);
            outlinePipeline.add(monsterSprite, {
              thickness: 1,
              outlineColor: 0x00ff00,
            });
          }

          // Add pointer down event for displaying a pop-up
          monsterSprite.on("pointerdown", () => {
            this.displayPopUp(monsterSprite, monster);
          });

          // Add colliders for the monsters to make them bounce off each other
          this.physics.add.collider(
            this.monsterGroup,
            this.monsterGroup,
            null,
            null,
            this
          );
        });
      } catch (error) {
        console.warn(error);
      }
    }

    handleMonsterCollision(monster1, monster2) {
      // Optional: You can add additional behavior when monsters collide
      console.log(
        `Monster ${monster1.texture.key} collided with Monster ${monster2.texture.key}`
      );
    }

    displayPopUp(monsterSprite, monster) {
      if (this.currentPopUp) {
        this.currentPopUp.destroy();
      }
      // Calculate the scale factor based on camera zoom
      const zoomFactor = this.cameras.main.zoom;
      // Create a small pop-up container relative to the monster's position
      const popUpContainer = this.add.container(
        monsterSprite.x,
        monsterSprite.y - 8
      ); // Place slightly above the monster

      // Adjust the container scale based on the zoom level
      popUpContainer.setScale(2 / zoomFactor); // Invert the zoom to keep it visually scaled with the monster

      // Add background for the pop-up
      const background = this.add.graphics();
      background.fillStyle(0x222222, 0.9); // Dark background with slight transparency
      background.fillRoundedRect(-10, -10, 200, 50, 3); // Small rounded rectangle

      // Add text information (use monster data directly)
      const name = this.add.text(
        -8,
        -8,
        `${monster.monster_name}${
          monster.can_collect ? " >>> Collect 🚀" : ""
        }`,
        {
          font: "8px Chicago_12", // Increase font size to counteract the zoom effect
          fill: "#ffd733",
          resolution: 2, // Increase resolution to prevent blurring
          wordWrap: { width: 140 }, // Wrap the text within the small box
        }
      );
      const hp = this.add.text(-8, 0, `HP: ${monster.hp} MH/s`, {
        font: "8px Chicago_12", // Increase font size to counteract the zoom effect
        fill: "#ffffff",
        resolution: 2, // Increase resolution to prevent blurring
        wordWrap: { width: 50 }, // Wrap the text within the small box
      });
      const divider = this.add.text(
        -8,
        8,
        `${
          monster.can_evolve === true
            ? ">>> Evolve 🧬"
            : "Required Items to Evolve"
        }`,
        {
          font: "8px Chicago_12", // Increase font size to counteract the zoom effect
          fill: "#ff0000",
          resolution: 2, // Increase resolution to prevent blurring
          wordWrap: { width: 130 }, // Wrap the text within the small box
        }
      );
      const evolveItems = this.add.text(
        -8,
        16,
        `🪵 ${JSON.parse(sessionStorage.getItem("user")).wood}/${
          monster.wood_required
        } • 🥩 ${JSON.parse(sessionStorage.getItem("user")).meat}/${
          monster.meat_required
        } • 🧙 ${JSON.parse(sessionStorage.getItem("user")).potion}/${
          monster.potion_required
        } • 💰 ${JSON.parse(sessionStorage.getItem("user")).coin}/${
          monster.coin_required
        }`,
        {
          font: "8px Chicago_12", // Increase font size to counteract the zoom effect
          fill: "#ffffff",
          resolution: 2, // Increase resolution to prevent blurring
          wordWrap: { width: 195 }, // Wrap the text within the small box
        }
      );
      const dailyGainText = this.add.text(-8, 24, `Daily Gain >>> $ USD $`, {
        font: "8px Chicago_12", // Increase font size to counteract the zoom effect
        fill: "#ffd733",
        resolution: 2, // Increase resolution to prevent blurring
        wordWrap: { width: 130 }, // Wrap the text within the small box
      });
      const dailyGainAmount = this.add.text(-8, 32, `$ ${monster.gain}`, {
        font: "8px Chicago_12", // Increase font size to counteract the zoom effect
        fill: "#fff",
        resolution: 2, // Increase resolution to prevent blurring
        wordWrap: { width: 130 }, // Wrap the text within the small box
      });

      // Add close button (a small X mark)
      const closeButton = this.add
        .text(185, -12, "X", {
          font: "9px Arial",
          fill: "#ff0000",
          backgroundColor: "#000000",
          resolution: 2, // Increase resolution to prevent blurring
        })
        .setInteractive({ useHandCursor: true });

      // Close button logic
      closeButton.on("pointerdown", () => {
        popUpContainer.destroy(); // Destroy the pop-up when the close button is clicked
      });

      // Add elements to the container
      popUpContainer.add([
        background,
        name,
        hp,
        divider,
        evolveItems,
        dailyGainText,
        dailyGainAmount,
        closeButton,
      ]);

      // Store pop-up reference in the monster object so we can update its position in `update()`
      monsterSprite.popUpContainer = popUpContainer;

      // Update the current pop-up reference to the newly created one
      this.currentPopUp = popUpContainer;
    }

    moveMonster(monsterSprite) {
      try {
        // Set an initial downward velocity
        monsterSprite.setVelocityY(10); // Adjust the value for speed control
        monsterSprite.setVelocityX(10); // Move right initially

        // Add an event to handle when the monster reaches a certain y coordinate and reverse its direction
        this.physics.world.on("worldbounds", (body) => {
          if (body.gameObject === monsterSprite) {
            // Reverse direction when hitting a boundary
            if (monsterSprite.body.velocity.y > 0 && monsterSprite.y >= 300) {
              // Reached a certain Y position
              monsterSprite.setVelocityY(-10); // Move upwards
            } else if (
              monsterSprite.body.velocity.y < 0 &&
              monsterSprite.y <= 100
            ) {
              // Reached the upper Y limit
              monsterSprite.setVelocityY(10); // Move downwards
            }
          }
        });

        // Ensure it stays within the world bounds
        monsterSprite.setCollideWorldBounds(true); // Keeps it within the bounds
        monsterSprite.setBounce(0); // No bounce effect

        this.setupMonsterMovement(monsterSprite);
      } catch (error) {
        console.warn(error);
      }
    }

    setupMonsterMovement(monsterSprite) {
      try {
        // Set an initial downward velocity
        monsterSprite.setVelocityY(10); // Start movement downward

        // Add a custom movement to reverse direction at specified y bounds
        this.events.on("update", () => {
          const lowerYBound = 450; // The lower boundary limit
          const upperYBound = 300; // The upper boundary limit

          if (monsterSprite.y >= lowerYBound) {
            monsterSprite.setVelocityY(-10); // Move upwards when reaching lower bound
          } else if (monsterSprite.y <= upperYBound) {
            monsterSprite.setVelocityY(10); // Move downwards when reaching upper bound
          }
        });
        this.events.on("update", () => {
          const lowerXBound = 50; // The lower boundary limit
          const upperXBound = 250; // The upper boundary limit

          if (monsterSprite.x >= lowerXBound) {
            monsterSprite.setVelocityX(-10);
          } else if (monsterSprite.x <= upperXBound) {
            monsterSprite.setVelocityX(10);
          }
        });
      } catch (error) {
        console.warn(error);
      }
    }

    //this function is used to collect coins
    goldClicked(pointer, objectClicked) {
      objectClicked.destroy();
      if (objectClicked.texture.key === "Gold_Coin") {
        this.collectedCoins++;
      }
      if (objectClicked.texture.key === "Gold_Sack") {
        this.collectedCoins = this.collectedCoins + 20;
      }
    }

    preload() {
      this.load.image("Dungeon_Tileset", "assets/Dungeon_Tileset.png");
      this.load.image("Gold_Sack", "assets/Resources/Resources/G_Idle.png");
      this.load.image("Gold_Coin", "assets/coin.png");
      this.load.audio("PickUpCoin", "assets/PickUpCoin.wav");

      //#region MONSTER ANIMATION
      const monsterNames = [
        "AntleredRascal",
        "ArmoredGoliath",
        "BeastlyImpalerIdleSide",
        "BladeHellion",
        "BloodDrinker",
        "BulgingIncubus",
        "ClawedAbomination",
        "CrimsonImp",
        "DastardlyCrusher",
        "DeadlyCambion",
        "DemonicArachnid",
        "DepravedBlackguard",
        "EbonAstaroth",
        "FledglingDemon",
        "FloatingEye",
        "FoulGouger",
        "GrinningGremlin",
        "HornedBrute",
        "NefariousScamp",
        "PitBalor",
        "PointedDemonspawn",
        "ProngedFury",
        "RascallyDemonling",
        "RedDevil",
        "SkeweringStalker",
        "SpikedRavager",
        "TaintedScoundrel",
        "ViciousMiscreant",
        "WarpSkull",
        "WickedWretch",
      ];

      monsterNames.forEach((monster) => {
        this.load.spritesheet(monster, `assets/monsters/${monster}.png`, {
          frameWidth: 16,
          frameHeight: 16,
        });
      });

      //#endregion

      this.load.tilemapTiledJSON("map", "assets/pupu-underworld.json");
      this.load.plugin(
        "rexoutlinepipelineplugin",
        "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexoutlinepipelineplugin.min.js",
        true
      );

      this.fetchMonsters().then(() => {
        console.log(">>> monsters fetched successfully.");
        console.log(">>> assets loaded completely.");
        this.startGame();
      });
    }

    startGame() {
      this.create();
    }

    create() {
      try {
        if (this.monsterList.length > 0) {
          this.createMonsters(this.monsterList);
          //phaserSceneRef.current.createMonsters(this.monsterList);
        }
        const map = this.make.tilemap({
          key: "map",
          tileWidth: 16,
          tileHeight: 16,
        });
        this.cameras.main.setZoom(5);
        this.cameras.main.centerOn(300, 300);

        const worldWidth = 480;
        const worldHeight = 320;
        this.cameras.main.setBounds(0, 0, worldWidth, worldHeight);

        //#region TILESETS and TILELAYERS
        const dungeontileset = map.addTilesetImage(
          "Dungeon_Tileset",
          "Dungeon_Tileset"
        );

        const floor = map.createLayer("Floor", [dungeontileset], 0, 0);
        const walls = map.createLayer("Walls", [dungeontileset], 0, 0);
        const decor = map.createLayer("Decor", [dungeontileset], 0, 0);
        //#endregion

        const pickupcoin = this.sound.add("PickUpCoin");
        let coins = [];

        for (let i = 0; i < coinCount; i++) {
          let coinimage = this.add
            .image(
              Phaser.Math.Between(50, 400),
              Phaser.Math.Between(50, 100),
              "Gold_Coin"
            )
            .setInteractive();
          coinimage.setScale(0.3);
          coins.push(coinimage);
          coinimage.on("pointerdown", () => {
            pickupcoin.play();
            coinimage.destroy();
            const body = {
              itemcode: "Coin",
            };
            const logset = axios
              .post(API_ENDPOINT + "collectItem", body, authconfig)
              .then(() => {
                setTimeout(() => {
                  coinimage.destroy();
                }, 1300);
              });
          });
        }

        for (let j = 0; j < goldsackCount; j++) {
          let goldsaaaaaack = this.add
            .image(
              Phaser.Math.Between(50, 400),
              Phaser.Math.Between(50, 100),
              "Gold_Sack"
            )
            .setInteractive();
          goldsaaaaaack.setScale(0.3);

          goldsaaaaaack.on("pointerdown", () => {
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const body = {
              itemcode: "GoldSack",
            };
            const sackset = axios
              .post(API_ENDPOINT + "collectItem", body, config)
              .then(() => {
                setTimeout(() => {
                  pickupcoin.play();
                  goldsaaaaaack.destroy();
                }, 1300);
              });
          });
        }
        this.sys.animatedTiles.init(map);

        //mouse drag map controls
        var cam = this.cameras.main;
        this.input.on("pointermove", function (p) {
          if (!p.isDown) return;
          cam.scrollX -= (p.x - p.prevPosition.x) / cam.zoom;
          cam.scrollY -= (p.y - p.prevPosition.y) / cam.zoom;
        });
        phaserSceneRef.current = this;
        document.body.style.overflow = "hidden";
      } catch (error) {
        console.log(error);
        setMessage("Oh no!", "Something went wrong!");
        //window.location.href = '/';
      }
    }

    update() {
      // If monsters exist, update their movement and move the pop-up with them
      if (this.monsters) {
        this.monsters.forEach((monsterSprite) => {
          const { minY, maxY, minX, maxX } = monsterSprite.customBounds;

          // Reverse velocity if it hits the Y bounds
          if (monsterSprite.y >= maxY && monsterSprite.body.velocity.y > 0) {
            monsterSprite.setVelocityY(-10); // Move upwards
          } else if (
            monsterSprite.y <= minY &&
            monsterSprite.body.velocity.y < 0
          ) {
            monsterSprite.setVelocityY(10); // Move downwards
          }

          // Reverse velocity if it hits the X bounds
          if (monsterSprite.x >= maxX && monsterSprite.body.velocity.x > 0) {
            monsterSprite.setVelocityX(-10); // Move left
          } else if (
            monsterSprite.x <= minX &&
            monsterSprite.body.velocity.x < 0
          ) {
            monsterSprite.setVelocityX(10); // Move right
          }

          // Flip the sprite based on X velocity direction
          if (monsterSprite.body.velocity.x > 0) {
            monsterSprite.setFlipX(false); // Facing right
          } else if (monsterSprite.body.velocity.x < 0) {
            monsterSprite.setFlipX(true); // Facing left
          }

          // Update the position of the pop-up if it exists
          if (monsterSprite.popUpContainer) {
            monsterSprite.popUpContainer.setPosition(
              monsterSprite.x,
              monsterSprite.y - 20
            ); // Keep the pop-up above the monster
          }
        });
      }
    }
  }

  const config = {
    type: Phaser.AUTO,
    backgroundColor: "rgb(37, 19, 26)",
    pixelArt: true,
    scene: [Example, UIScene],
    physics: {
      default: "arcade",
      arcade: {
        gravity: { y: 0 },
      },
    },
    scale: {
      mode: Phaser.Scale.RESIZE,
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    plugins: {
      scene: [
        {
          key: "AnimatedTiles",
          plugin: AnimatedTiles,
          mapping: "animatedTiles",
        },
      ],
    },
  };

  return (
    <>
      <GameComponent config={config} />
      {modalShow && (
        <Modal onClose={closeModal} props={[message[0], message[1]]} />
      )}
    </>
  );
};

export default Underworld;
