import React from "react";
import Phaser from "phaser";
import GameComponent from "../components/GameComponent";
import AnimatedTiles from "phaser-animated-tiles/src/plugin/main";
import UIScene from "../components/UIScene";
import axios from "axios";

const Village = () => {
  const token = sessionStorage.getItem("token");
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const playerItems = JSON.parse(sessionStorage.getItem("props"));
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  let sheepGroup;
  const bouncingSheepToSpawn = parseInt(playerItems.HappySheep_Bouncing);
  const idleSheepToSpawn = parseInt(playerItems.HappySheep_Idle);
  const treeToSpawn = parseInt(playerItems.Tree);

  let beefCounter = 0;
  let logCounter = 0;
  class Example extends Phaser.Scene {
    constructor() {
      super({ key: "village-scene", active: true });
    }
    coinsCollected = false;

    preload() {
      this.load.image("15", "assets/Deco/15.png");
      this.load.image("16", "assets/Deco/16.png");
      this.load.image("17", "assets/Deco/17.png");
      this.load.image("18", "assets/Deco/18.png");
      this.load.image("Bridge_All", "assets/Terrain/Bridge/Bridge_All.png");
      this.load.image(
        "Castle_Blue",
        "assets/Factions/Knights/Buildings/Castle/Castle_Blue.png"
      );
      this.load.image(
        "House_Blue",
        "assets/Factions/Knights/Buildings/House/House_Blue.png"
      );
      this.load.image(
        "Tower_Blue",
        "assets/Factions/Knights/Buildings/Tower/Tower_Blue.png"
      );
      this.load.image("Foam", "assets/Terrain/Water/Foam/Foam.png");
      this.load.image("crystal", "assets/crystal.png");
      this.load.image("Fire", "assets/Effects/Fire/Fire.png");
      this.load.image("Rocks_04", "assets/Terrain/Water/Rocks/Rocks_04.png");
      this.load.image("Water", "assets/Terrain/Water/Water.png");
      this.load.image("Tilemap_Flat", "assets/Terrain/Ground/Tilemap_Flat.png");
      this.load.image(
        "Tilemap_Elevation",
        "assets/Terrain/Ground/Tilemap_Elevation.png"
      );
      this.load.image(
        "GoldMine_Active",
        "assets/Resources/GoldMine/GoldMine_Active.png"
      );
      this.load.image("Tree", "assets/Resources/Trees/Tree.png");
      this.load.image(
        "Warrior_Blue",
        "assets/Factions/Knights/Troops/Warrior/Blue/Warrior_Blue.png"
      );

      this.load.image("seamonster", "assets/seamonster.png");
      this.load.image("seamonster_hurt", "assets/seamonster_hurt.png");

      this.load.image("coin", "assets/coin.png");
      this.load.image("empty_tile", "assets/empty_tile.png");
      this.load.image("banner", "assets/banner.png");

      this.load.audio("SlaySheep", "assets/tap.wav");
      this.load.audio("CutTree", "assets/explosion.wav");

      this.load.tilemapTiledJSON("map", "assets/pupu.json");

      this.isCollectedCoins = null;
      this.collectedCoins = 0;

      this.load.plugin(
        "rexoutlinepipelineplugin",
        "https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexoutlinepipelineplugin.min.js",
        true
      );

      this.load.spritesheet(
        "HappySheep_Bouncing",
        "assets/Resources/Sheep/HappySheep_Bouncing.png",
        { frameWidth: 128, frameHeight: 128 }
      );
      this.load.spritesheet(
        "HappySheep_Idle",
        "assets/Resources/Sheep/HappySheep_Idle.png",
        { frameWidth: 128, frameHeight: 128 }
      );

      this.load.spritesheet(
        "M_Spawn",
        "assets/Resources/Resources/M_Spawn.png",
        { frameWidth: 128, frameHeight: 128 }
      );
      this.load.spritesheet("TreeLong", "assets/Resources/Trees/TreeLong.png", {
        frameWidth: 192,
        frameHeight: 192,
      });
      this.load.spritesheet(
        "W_Spawn",
        "assets/Resources/Resources/W_Spawn.png",
        { frameWidth: 128, frameHeight: 128 }
      );
    }

    create() {
      try {
        //make a server call to get the collect coins parameter. if it is not collected yet, update this.collectedCoins to false.
        this.isCollectedCoins = false;
        var postFxPlugin = this.plugins.get("rexoutlinepipelineplugin");

        const map = this.make.tilemap({
          key: "map",
          tileWidth: 64,
          tileHeight: 64,
        });
        this.cameras.main.setZoom(1);
        this.cameras.main.centerOn(320, 350);

        const worldWidth = 1920;
        const worldHeight = 1280;
        this.cameras.main.setBounds(0, 0, worldWidth, worldHeight);
        this.cameras.main.setScroll(worldWidth / 2, worldHeight / 2); // Start in the middle if you prefer

        //#region TILESETS and TILELAYERS

        const _15 = map.addTilesetImage("15", "15");
        const _16 = map.addTilesetImage("16", "16");
        const _17 = map.addTilesetImage("17", "17");
        const _18 = map.addTilesetImage("18", "18");
        const bridge = map.addTilesetImage("Bridge_All", "Bridge_All");
        const vault = map.addTilesetImage("Castle_Blue", "Castle_Blue");
        const wateranimation = map.addTilesetImage("Foam", "Foam");
        const fire = map.addTilesetImage("Fire", "Fire");
        const crystal = map.addTilesetImage("crystal", "crystal");
        const goldmine = map.addTilesetImage(
          "GoldMine_Active",
          "GoldMine_Active"
        );
        //const sheepall = map.addTilesetImage('HappySheep_All','HappySheep_All');
        const rock04 = map.addTilesetImage("Rocks_04", "Rocks_04");
        const flat = map.addTilesetImage("Tilemap_Flat", "Tilemap_Flat");
        const elevation = map.addTilesetImage(
          "Tilemap_Elevation",
          "Tilemap_Elevation"
        );
        const tower = map.addTilesetImage("Tower_Blue", "Tower_Blue");
        const tree = map.addTilesetImage("Tree", "Tree");
        const warrior = map.addTilesetImage("Warrior_Blue", "Warrior_Blue");
        const water = map.addTilesetImage("Water", "Water");
        const sm = map.addTilesetImage("seamonster", "seamonster");
        const smh = map.addTilesetImage("seamonster_hurt", "seamonster_hurt");

        const sea = map.createLayer(
          "Sea",
          [
            _15,
            _16,
            _17,
            bridge,
            vault,
            wateranimation,
            fire,
            crystal,
            goldmine,
            rock04,
            flat,
            elevation,
            tower,
            tree,
            warrior,
            water,
          ],
          0,
          0
        );
        const seaanimation = map.createLayer(
          "SeaAnimation",
          [
            _15,
            _16,
            _17,
            bridge,
            vault,
            wateranimation,
            fire,
            crystal,
            goldmine,
            rock04,
            flat,
            elevation,
            tower,
            tree,
            warrior,
            water,
            sm,
            smh,
          ],
          0,
          0
        );
        const terrain = map.createLayer(
          "Terrain",
          [
            _15,
            _16,
            _17,
            bridge,
            vault,
            wateranimation,
            fire,
            crystal,
            goldmine,
            rock04,
            flat,
            elevation,
            tower,
            tree,
            warrior,
            water,
          ],
          0,
          0
        );
        const grass = map.createLayer(
          "Grass",
          [
            _15,
            _16,
            _17,
            bridge,
            vault,
            wateranimation,
            fire,
            crystal,
            goldmine,
            rock04,
            flat,
            elevation,
            tower,
            tree,
            warrior,
            water,
          ],
          0,
          0
        );
        const structures = map.createLayer(
          "Structures",
          [
            _15,
            _16,
            _17,
            bridge,
            vault,
            wateranimation,
            fire,
            crystal,
            goldmine,
            rock04,
            flat,
            elevation,
            tower,
            tree,
            warrior,
            water,
          ],
          0,
          0
        );
        const decor = map.createLayer(
          "Decor",
          [
            _15,
            _16,
            _17,
            bridge,
            vault,
            wateranimation,
            fire,
            crystal,
            goldmine,
            rock04,
            flat,
            elevation,
            tower,
            tree,
            warrior,
            water,
          ],
          0,
          0
        );
        const decoranimation = map.createLayer(
          "DecorAnimation",
          [
            _15,
            _16,
            _17,
            bridge,
            vault,
            wateranimation,
            fire,
            crystal,
            goldmine,
            rock04,
            flat,
            elevation,
            tower,
            tree,
            warrior,
            water,
          ],
          0,
          0
        );

        //#endregion

        const clickableGoldMine = this.add.image(1500, 640, "GoldMine_Active");
        clickableGoldMine.setInteractive({ useHandCursor: true });
        clickableGoldMine
          .on("pointerover", () => {
            // setTint method takes a color value & change the image's color accordingly
            postFxPlugin.add(clickableGoldMine, {
              thickness: 3,
              outlineColor: 0x0099ffff,
            });
          })
          .on("pointerout", () => {
            // Remove all outline post-fx pipelines
            postFxPlugin.remove(clickableGoldMine);
          })
          .on("pointerdown", () => {
            //navigate to dungeon
            //navigate('/underworld');
            window.location.href = "/underworld";
          });

        const clickableTreasury = this.add.image(1570, 110, "Castle_Blue");
        clickableTreasury.setInteractive({ useHandCursor: true });
        clickableTreasury
          .on("pointerover", () => {
            // setTint method takes a color value & change the image's color accordingly
            postFxPlugin.add(clickableTreasury, {
              thickness: 3,
              outlineColor: 0x0099ffff,
            });
          })
          .on("pointerout", () => {
            // Remove all outline post-fx pipelines
            postFxPlugin.remove(clickableTreasury);
          })
          .on("pointerdown", () => {
            //navigate to dungeon
            //navigate('/treasury');
            window.location.href = "/treasury";
          });

        const clickableTile = this.add.image(610, 150, "empty_tile");
        clickableTile.setInteractive({ useHandCursor: true });
        clickableTile
          .on("pointerover", () => {
            // setTint method takes a color value & change the image's color accordingly
            postFxPlugin.add(clickableTile, {
              thickness: 3,
              outlineColor: 0x0099ffff,
            });
          })
          .on("pointerout", () => {
            // Remove all outline post-fx pipelines
            postFxPlugin.remove(clickableTile);
          })
          .on("pointerdown", () => {
            //navigate to alchemist
            //navigate('/alchemist');
            window.location.href = "/alchemist";
          });

        // location banners
        const alchemylabBanner = this.add
          .image(610, 100, "banner")
          .setScale(0.6, 0.6);
        this.add.text(570, 85, "Alchemist", {
          font: "15px Warsuck",
          fill: "#000",
        });

        const treasuryBanner = this.add
          .image(1360, 100, "banner")
          .setScale(0.6, 0.6);
        this.add.text(1330, 85, "Treasury", {
          font: "15px Warsuck",
          fill: "#000",
        });

        const underworldBanner = this.add
          .image(1650, 650, "banner")
          .setScale(0.6, 0.6);
        this.add.text(1615, 635, "Underworld", {
          font: "15px Warsuck",
          fill: "#000",
        });

        //#region SHEEP ANIMATION
        //creates animation config for sheep animation, tree animation, beef animation - on run time
        const sheepanimConfig1 = {
          key: "bounce",
          frames: this.anims.generateFrameNumbers("HappySheep_Bouncing", {
            end: 5,
          }),
          frameRate: 6,
          repeat: -1,
        };
        const sheepanimConfig2 = {
          key: "idle",
          frames: this.anims.generateFrameNumbers("HappySheep_Idle", {
            end: 5,
          }),
          frameRate: 10,
          repeat: -1,
        };
        const beefanimConfig1 = {
          key: "beefspawn",
          frames: this.anims.generateFrameNumbers("M_Spawn", { end: 5 }),
          frameRate: 6,
          repeat: -1,
        };
        const treeanimConfig1 = {
          key: "treespawn",
          frames: this.anims.generateFrameNumbers("TreeLong", { end: 5 }),
          frameRate: 6,
          repeat: -1,
        };
        const loganimConfig1 = {
          key: "logspawn",
          frames: this.anims.generateFrameNumbers("W_Spawn", { end: 5 }),
          frameRate: 6,
          repeat: -1,
        };
        this.anims.create(beefanimConfig1);
        this.anims.create(sheepanimConfig1);
        this.anims.create(sheepanimConfig2);
        this.anims.create(treeanimConfig1);
        this.anims.create(loganimConfig1);
        const slaysheep = this.sound.add("SlaySheep");
        const cuttree = this.sound.add("CutTree");
        // Create a group for the sheep
        sheepGroup = this.add.group();

        for (let i = 0; i < bouncingSheepToSpawn; i++) {
          let x = Phaser.Math.Between(1, 4);
          let y = Phaser.Math.Between(1, 8);
          // Create each sheep sprite individually
          const sheep = this.add.sprite(
            960 + x * 64,
            320 + y * 64,
            "HappySheep_Bouncing"
          );
          // Set the sheep as interactive
          sheep.setInteractive({ useHandCursor: true });
          // Play the 'bounce' animation for each sheep
          sheep.play("bounce");
          // Add the sheep to the group
          sheepGroup.add(sheep);
          // Add a pointerdown event listener for each sheep
          sheep.on("pointerdown", () => {
            // Perform any action, e.g., destroy the sheep
            slaysheep.play();
            const beef = this.add.sprite(sheep.x, sheep.y, "M_Spawn");
            beef.play("beefspawn");
            sheep.destroy();

            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const body = {
              itemcode: "HappySheep_Bouncing",
            };
            const bouncingset = axios
              .post(API_ENDPOINT + "collectItem", body, config)
              .then(() => {
                setTimeout(() => {
                  beef.destroy();
                }, 1300);
              });
          });
        }
        for (let j = 0; j < idleSheepToSpawn; j++) {
          let x = Phaser.Math.Between(1, 10);
          let y = Phaser.Math.Between(1, 3);
          // Create each sheep sprite individually
          const sheep = this.add.sprite(
            640 + x * 64,
            704 + y * 64,
            "HappySheep_Idle"
          );
          // Set the sheep as interactive
          sheep.setInteractive({ useHandCursor: true });
          // Play the 'bounce' animation for each sheep
          sheep.play("idle");
          // Add the sheep to the group
          sheepGroup.add(sheep);
          // Add a pointerdown event listener for each sheep
          sheep.on("pointerdown", () => {
            slaysheep.play();
            const beef = this.add.sprite(sheep.x, sheep.y, "M_Spawn");
            beef.play("beefspawn");
            sheep.destroy();
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const body = {
              itemcode: "HappySheep_Idle",
            };
            const idleset = axios
              .post(API_ENDPOINT + "collectItem", body, config)
              .then(() => {
                setTimeout(() => {
                  beef.destroy();
                }, 1300);
              });
          });
        }
        for (let k = 0; k < treeToSpawn; k++) {
          let x = Phaser.Math.Between(1, 10);
          let y = Phaser.Math.Between(1, 3);
          const tree = this.add.sprite(640 + x * 64, 704 + y * 64, "TreeLong");
          tree.setInteractive({ useHandCursor: true });
          tree.play("treespawn");
          tree.on("pointerdown", () => {
            cuttree.play();
            const log = this.add.sprite(tree.x, tree.y, "W_Spawn");
            log.play("logspawn");
            tree.destroy();
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const body = {
              itemcode: "Tree",
            };
            const logset = axios
              .post(API_ENDPOINT + "collectItem", body, config)
              .then(() => {
                setTimeout(() => {
                  log.destroy();
                }, 1300);
              });
          });
        }

        this.state = {
          collectedBeef: beefCounter,
          isCollectedCoins: true,
          collectedLog: logCounter,
        };
        // IMPORTANT - set these state variables to the daily log value of the user progress table.

        //#endregion
        this.sys.animatedTiles.init(map);

        //mouse drag map controls
        var cam = this.cameras.main;
        this.input.on("pointermove", function (p) {
          if (!p.isDown) return;
          cam.scrollX -= (p.x - p.prevPosition.x) / cam.zoom;
          cam.scrollY -= (p.y - p.prevPosition.y) / cam.zoom;
        });
        //this.scene.launch('ui-scene', { state: location.state });
      } catch (error) {
        console.warn(error);
        //window.location.href = "/";
      }
    }
  }

  const config = {
    type: Phaser.AUTO,
    backgroundColor: "rgb(71, 171, 169)",
    pixelArt: true,
    scene: [Example, UIScene],
    physics: {
      default: "arcade",
      arcade: {
        gravity: { y: 0 },
      },
    },
    scale: {
      mode: Phaser.Scale.RESIZE,
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    plugins: {
      scene: [
        {
          key: "AnimatedTiles",
          plugin: AnimatedTiles,
          mapping: "animatedTiles",
        },
      ],
    },
  };

  return <GameComponent config={config} />;
};

export default Village;
